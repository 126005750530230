import React, { useRef, useEffect, useState } from 'react'
import { Link, useMatch, useResolvedPath } from 'react-router-dom'
import Toggles from '../../component/toggle/LanguageToggle'
import { FaBars, FaTimes } from 'react-icons/fa'
import './Navbar.css'
import { useTranslation } from 'react-i18next'
import { HiQuestionMarkCircle } from "react-icons/hi"
import axios from "axios";
import i18next from 'i18next'

export default () => {
    useEffect(() => {
        getMonthltData()
    }, [])
    const [monthlyData, setmonthlyData] = useState({
        tkb: '96.6',
        tkb0: '85.1',
        tkb30: '90.0',
        tkb60: '93.3'
    })

    async function getMonthltData() {
        try{
            await axios.post('https://gateway.pendanaan.com/kta/api/v1/ojk/listOjkRecord', {}, {
                headers: {
                    'tenant': "KTAID",
                    'Content-Type': "application/json",
                    "Accept-Language": i18next.language
                }
            }).then((response) => {
                if(response.data.success){
                    setmonthlyData(response.data.data[0])
                }
            })
        }catch(e){
            console.log("error: " + e)
            return;
        }
    }

    const[isEnglish,setIsEnglish]=useState(false)
    const [sideBar, setSideBar] = useState(true)
    const [isTKB, setIsTKB] = useState(false)
    const showSideBar = () => setSideBar(!sideBar)

    function useOutsideAlerter(ref) {
        useEffect(() => {
          /**
           * Alert if clicked on outside of element
           */
          function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                if(isTKB) {
                    setIsTKB(false)
                }
            }
          }
          // Bind the event listener
          document.addEventListener("mousedown", handleClickOutside);
          return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
          };
        }, [ref]);
      }

    const [colorChange, setColorchange] = useState(false)
    const changeNavbarColor = () =>{
        if(window.scrollY >= 30){
          setColorchange(true);
        }
        else{
          setColorchange(false);
        }
     }
    window.addEventListener('scroll', changeNavbarColor)

    const {t,i18n} = useTranslation()
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);
    return(
        <>
        <nav className={`nav justify-center ${colorChange ? 'navbar colorChange' : 'navbar'} transform delay-150 z-20`}>
            <div className='flex items-center justify-between w-[100%] h-[100%] pc-3:w-[1366px]'>
                <div className = 'image-container'>
                    <Link to = '/'>
                        <div className='w-[225px] object-cover'>
                            <img src={require('../../assets/images/pendanaan-logo.png')} alt='pendanaan-logo'/>
                        </div>
                    </Link>
                    {/* <div className='hidden pc-1:block pc-1:w-[250px] xl:w-[300px] object-cover'>
                        <img src={require('../../assets/images/group_logo.png')} alt='ojk-afpi-logo'/>
                    </div> */}
                </div>

                <ul className={`${ sideBar ? '-right-[100%]': 'right-0' } text-slate-900 px-5 pc-1:px-10 pc-3:text-center z-10`} key='menu'>
                    <button className = 'menu absolute top-0 right-0 mt-8 mr-5 text-slate-900' onClick={showSideBar}>
                        <FaTimes/>
                    </button>
                    <div>
                        <div className='w-[225px] object-cover flex pc-2:hidden items-center h-[26px] -ml-3'>
                            <img src={require('../../assets/images/pendanaan-logo.png')} alt='pendanaan-logo'/>
                        </div>
                    </div>
                    <CustomLink to = '/' onClick={showSideBar}>{t('home.navbar')}</CustomLink>
                    <div className='flex xl:hidden w-[100%] h-[1px] bg-colorOrangeDark'/>
                    <CustomLink to = '/about-us' onClick={showSideBar}>{t('about.navbar')}</CustomLink>
                    <div className='flex xl:hidden w-[100%] h-[1px] bg-colorOrangeDark'/>
                    <CustomLink to = '/news' onClick={showSideBar}>{t('news.navbar')}</CustomLink>
                    <div className='flex xl:hidden w-[100%] h-[1px] bg-colorOrangeDark'/>
                    <CustomLink to = '/faq' onClick={showSideBar}>{t('faq.navbar')}</CustomLink>
                    <div className='flex xl:hidden w-[100%] h-[1px] bg-colorOrangeDark'/>
                    <CustomLink to = '/life-at-pendanaan' onClick={showSideBar}>{t('lifeatpendanaan.navbar')}</CustomLink>
                    <div className='flex xl:hidden w-[100%] h-[1px] bg-colorOrangeDark'/>
                    <CustomLink to = '/lender' onClick={showSideBar}>Lender</CustomLink>
                    <span className='hidden xl:block'>|</span>
                    <div className='flex xl:hidden w-[100%] h-[1px] bg-colorOrangeDark'/>
                    <div className='flex justify-between'>
                    <div className='flex pc-2:hidden items-center h-[26px]'>
                        <div className='w-[250px] object-cover'>
                            <img src={require('../../assets/images/group_logo.png')} alt='ojk-afpi-logo'/>
                        </div>

                        {/* <div className='w-[26px] object-cover ml-2'>
                            <img src={require('../../assets/images/iso.png')} alt='iso'/>
                        </div> */}
                    </div>
                        <div>
                            {/* <Toggles/> */}
                            <label className='toggle-container'>
                                <input type='checkbox' 
                                // onClick={()=>{setIsEnglish(!isEnglish)}}
                                onClick={showSideBar}
                                onChange={()=>{
                                    setIsEnglish(!isEnglish)
                                    if(isEnglish){
                                        i18n.changeLanguage('in-ID')
                                    }else{
                                        i18n.changeLanguage('en')
                                    }
                                }}/>
                                <span className='title right-1'>IN</span> 
                                <span className='title right-7'>EN</span>
                                <span className={`indicator ${isEnglish?'transform transition-transform translate-x-[24px] bg-[url("./assets/images/english.png")] bg-cover':'transform transition-transform translate-x-[0px] bg-[url("./assets/images/indonesia.png")] bg-cover'}`}/>
                            </label>
                        </div>
                    </div>

                    <div className='relative hidden pc-2:grid place-items-center font-customRegular'>
                        <div className='w-fit h-fit bg-[#E0A234] px-3 py-[2px] cursor-pointer rounded-full'
                        onMouseEnter={()=>{setIsTKB(!isTKB)}}
                        onMouseLeave={()=>{setIsTKB(!isTKB)}}>
                            <div className='flex items-center py-[1px] text-[14px] text-white'>
                            {t('check.tkb')}&nbsp;&nbsp;&nbsp;<HiQuestionMarkCircle />
                            </div>
                        </div>

                        <div ref={wrapperRef} className={`absolute ${isTKB ? 'block' : 'hidden'} w-[140px] top-8 text-[14px]`}>
                            <img src={require('../../assets/images/pop-up.png')} alt='ojk-afpi-logo'/>
                                <div className='flex text-center'>
                                    <div className='absolute top-3 p-3 w-[100%] h-[100%]'>
                                        TKB0 : {monthlyData['tkb0']}%
                                        <br/>
                                        TKB30 : {monthlyData['tkb30']}%
                                        <br/>
                                        TKB60 : {monthlyData['tkb60']}%
                                        <br/>
                                        TKB90 : {monthlyData['tkb']}%
                                    </div>
                                </div>
                        </div>
                    </div>
                </ul>

                    <div className='absolute right-10 pc-1:right-16 grid pc-1:hidden place-items-center font-customRegular'>
                        <div className='w-fit h-fit bg-[#E0A234] px-3 py-[2px] cursor-pointer rounded-full'
                        onMouseEnter={()=>{setIsTKB(true)}}
                        onMouseLeave={()=>{setIsTKB(false)}}
                        onClick={()=>{setIsTKB(!isTKB)}}>
                            <div className='flex items-center text-[12px] py-[2px] text-white'>
                            TKB&nbsp;&nbsp;&nbsp;<HiQuestionMarkCircle />
                            </div>
                        </div>

                        <div ref={wrapperRef} className={`absolute ${isTKB ? 'block' : 'hidden'} w-[140px] top-8 text-[14px]`}>
                            <div className='grid place-items-center'>
                                <div className='arrow bg-gray-300 mt-[2px] shadow-md'/>
                                <div className='arrow-2 bg-white mt-[2px] z-20'/>
                                <div className='w-[135px] h-fit rounded-2xl z-10 mt-[10px] bg-white border'>
                                    <div className='p-3 w-[100%] h-[100%]'>
                                        TKB0 : {monthlyData['tkb0']}%
                                        <br/>
                                        TKB30 : {monthlyData['tkb30']}%
                                        <br/>
                                        TKB60 : {monthlyData['tkb60']}%
                                        <br/>
                                        TKB90 : {monthlyData['tkb']}%
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <button className = 'menu text-slate-900' onClick={showSideBar}>
                        <FaBars/>
                    </button>
                </div>
        </nav>
        </>
    )
}

function CustomLink({ to, children, ...props }){
    const resolvePath = useResolvedPath(to)
    const isActive = useMatch({path: resolvePath.pathname, end: true})
    return(
        <li className = {isActive ? 'active' : ''}>
            <Link to = {to} {...props}>{children}</Link>
        </li>
    )
}