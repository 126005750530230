import { BsChevronDown, BsDot } from 'react-icons/bs'
import { useTranslation } from 'react-i18next'

export default () =>{
    const {t,i18n} = useTranslation()
    const items = [{
        'question':`${t('forth.faq.general.question1.title')}`,
        'answer':<div>{t('forth.faq.general.question1.text1')}</div>
    },{
        'question':`${t('forth.faq.general.question2.title')}`,
        'answer':<div>{t('forth.faq.general.question2.text1')}<br/>
        {t('forth.faq.general.question2.text2')}<br/>
        {t('forth.faq.general.question2.text3')}<br/>
        {t('forth.faq.general.question2.text4')}<br/>
        {t('forth.faq.general.question2.text5')}<br/></div>
    },{
        'question':`${t('forth.faq.general.question3.title')}`,
        'answer':<div>{t('forth.faq.general.question3.text1')}</div>
    },{
        'question':`${t('forth.faq.general.question4.title')}`,
        'answer':<div>{t('forth.faq.general.question4.text1')}</div>
    },{
        'question':`${t('forth.faq.general.question5.title')}`,
        'answer':<div>{t('forth.faq.general.question5.text1')}</div>
    },{
        'question':`${t('forth.faq.general.question6.title')}`,
        'answer':<div>{t('forth.faq.general.question6.text1')}</div>
    }]
    return(
        <>
        <div className='px-5 lg:px-14 py-5 lg:py-10'>
        {
            items.map((i)=>{
                return(
                    <div className='relative overflow-hidden mb-3 rounded-lg border border-solid border-gray-100 shadow-md'>
                        <input type='checkbox' className='peer absolute inset-0 w-full h-[100%] opacity-0 z-10 cursor-pointer'/>
                        <div className='w-[85%] lg:w-[100%] pl-5 py-5 flex items-center mr-10 text-[14px] font-customBold peer-checked:text-colorOrangeDark'>
                            <h1>{i.question}</h1>
                        </div>

                        <div className='absolute top-[23px] right-5 transition-transform duration-500 rotate-0 peer-checked:-rotate-180'>
                            <BsChevronDown/>
                        </div>

                        <div className='overflow-hidden text-[14px] font-customRegular bg-gray-50 transition-all duration-500 max-h-0 peer-checked:max-h-fit'>
                            <p className='px-5 py-5 border-t'>{i.answer}</p>
                        </div>
                    </div>
                    )})}
        </div>

        <div className='block pc-1:flex w-[100%] h-fit justify-center items-center gap-5 mb-10 px-5'>
            <div className='w-[100%] pc-1:w-[30%] h-[315px] pc-1:h-[265px] rounded-3xl border-[1px] bg-white shadow-lg p-5'>
                <div className='flex items-center gap-2'>
                    <img src={require('../../../assets/images/frame_a.png')} className='w-[75px] h-[75px] object-cover'/>
                    <p className='font-customBold text-[18px]'>{t('forth.faq.general.question7.title')}</p>
                </div>

                <p className='mt-2 text-[14px] font-customRegular'>{t('forth.faq.general.question7.text1')}</p>
            </div>

            <div className='w-[100%] pc-1:w-[30%] h-[270px] rounded-3xl border-[1px] bg-white shadow-lg p-5 mt-5 pc-1:mt-0'>
                <div className='flex items-center gap-2'>
                    <img src={require('../../../assets/images/frame_b.png')} className='w-[75px] h-[75px] object-cover'/>
                    <p className='font-customBold text-[18px]'>{t('forth.faq.general.question8.title')}</p>
                </div>

                <p className='mt-2 text-[14px] font-customRegular'>{t('forth.faq.general.question8.text1')}</p>
            </div>

            <div className='w-[100%] pc-1:w-[30%] h-[270px] rounded-3xl border-[1px] bg-white shadow-lg p-5 mt-5 pc-1:mt-0'>
                <div className='flex items-center gap-2'>
                    <img src={require('../../../assets/images/frame_c.png')} className='w-[75px] h-[75px] object-cover'/>
                    <p className='font-customBold text-[18px]'>{t('forth.faq.general.question9.title')}</p>
                </div>

                <p className='mt-2 text-[14px] font-customRegular'>{t('forth.faq.general.question9.text1')}</p>
            </div>
        </div>
        </>
    )
}